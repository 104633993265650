// ConfigBotPDF.js
import React, { useState, useEffect } from 'react';
import { Typography, Button, TextField, Box, Container, CircularProgress, Snackbar, Alert, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { CloudUpload } from '@mui/icons-material';
import { QRCode as QRCodeGenerator } from 'react-qrcode-logo';
import { getQRCode, checkSessionStatus, setAutonomousMode } from '../services/client';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import SessionService from '../services/session';
import PendingResponses from './pendingResponse';
import GoHomeButton from './goHomeButton';
import { useGlobal } from '../context/globalContex';


const ConfigBotPDF = () => {
  const [companyName, setCompanyName] = useState(''); // Nombre de la empresa
  const [file, setFile] = useState(null); // Estado para manejar el archivo PDF
  const [qrCodeData, setQrCodeData] = useState(null); // Estado para almacenar el QR
  const [loading, setLoading] = useState(false); // Estado de carga
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Estado de autenticación
  const [showQRCode, setShowQRCode] = useState(false); // Estado para mostrar QR o formulario
  const [sessionData, setSessionData] = useState(null); // Guardar la sesión autenticada
  const [isAutonomous, setIsAutonomous] = useState(false); // Estado para el modo autónomo
  const navigate = useNavigate();
  const theme = useTheme(); // Tema global
  const location = useLocation();
  const userSessionId = location.state?.userSessionId; // Obtener userSessionId
  const [premiumDialogOpen, setPremiumDialogOpen] = useState(false);
  const { logoutUser, state } = useGlobal();
  const { user } = state;


  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: '',
  });

  // Validación: Asegurar que el archivo es PDF
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (selectedFile.type !== 'application/pdf') {
        setSnackbar({
          open: true,
          message: 'Solo se permiten archivos PDF.',
          severity: 'error',
        });
        return;
      }
      setFile(selectedFile);
      setSnackbar({
        open: true,
        message: `${selectedFile.name} seleccionado exitosamente.`,
        severity: 'success',
      });
    } else {
      setFile(null);
    }
  };

  // Navegar para enviar mensajes masivos si tiene suscripción activa
  const handleSendMasive = () => {
    if (user.activeSus) {
      navigate(`/sendWsMasive`, { state: { userSessionId } });
    } else {
      setPremiumDialogOpen(true);
    }
  };

  const handlePremiumDialogClose = () => {
    setPremiumDialogOpen(false);
  };


  // Manejar el envío de datos al backend
  const handleSubmit = async () => {
    if (!companyName.trim() || !file) {
      setSnackbar({
        open: true,
        message: 'Debes proporcionar el nombre de la empresa y subir un archivo PDF.',
        severity: 'error',
      });
      return;
    }

    const formData = new FormData();
    formData.append('userId', userSessionId); // Añadir userId para la sesión
    formData.append('empresa', companyName.trim()); // Enviar el nombre de la empresa
    formData.append('productPdf', file); // Enviar el PDF como FormData

    setLoading(true); // Activar estado de carga

    try {
      // Crear la sesión en el backend
      await SessionService.createSession(formData);
      setSnackbar({
        open: true,
        message: 'Sesión creada exitosamente.',
        severity: 'success',
      });

      // Generar el QR después de crear la sesión
      const qrData = await getQRCode(userSessionId); // Pasar solo userSessionId
      if (qrData && qrData.qr) {
        setQrCodeData(qrData.qr); // Almacenar el QR
        setShowQRCode(true); // Mostrar el QR
        setSnackbar({
          open: true,
          message: 'QR code generado exitosamente!',
          severity: 'success',
        });
      } else {
        setSnackbar({
          open: true,
          message: 'Error al generar el QR code.',
          severity: 'error',
        });
      }
    } catch (error) {
      console.error('Error creando sesión o generando el QR code:', error);
      const errorMessage = error.error || 'Error creando sesión o generando QR code.';
      setSnackbar({
        open: true,
        message: errorMessage,
        severity: 'error',
      });
    }
    setLoading(false); // Desactivar el estado de carga
  };

  // Efecto para verificar la autenticación del usuario escaneando el QR
  useEffect(() => {
    const checkSession = async () => {
        if (userSessionId) {
            try {
                const session = await checkSessionStatus(userSessionId);

                if (session.authenticated) {
                    // Actualiza los estados según la respuesta del backend
                    setSessionData({
                        wid: session.wid,
                        name: session.name,
                        platform: session.platform,
                    });
                    setIsAuthenticated(true);
                    setIsAutonomous(session.isAutonomous || false);

                    setSnackbar({
                        open: true,
                        message: `Sesión autenticada: ${session.name || 'Usuario desconocido'} en ${session.platform || 'plataforma desconocida'}.`,
                        severity: 'success',
                    });
                } else {
                    setIsAuthenticated(false);
                    setSnackbar({
                        open: true,
                        message: 'Sesión no autenticada. Por favor, escanea el código QR.',
                        severity: 'warning',
                    });
                }
            } catch (error) {
                console.error('Error al obtener la sesión:', error);
                setSnackbar({
                    open: true,
                    message: 'Error al obtener la sesión. Intenta de nuevo.',
                    severity: 'error',
                });
            }
        }
    };

    checkSession();
}, [userSessionId]);



  // Manejar el cambio de modo autónomo
  const handleModeChange = async () => {
    try {
      const newMode = !isAutonomous;
      await setAutonomousMode(userSessionId, newMode);
      setIsAutonomous(newMode);
      setSnackbar({
        open: true,
        message: `Modo ${newMode ? 'autónomo' : 'supervisado'} activado.`,
        severity: 'success',
      });
    } catch (error) {
      console.error('Error cambiando el modo:', error);
      setSnackbar({
        open: true,
        message: 'Error cambiando el modo.',
        severity: 'error',
      });
    }
  };

  // Manejar el cierre de sesión
  const handleLogout = async () => {
    try {
      await logoutUser();
      setSnackbar({ open: true, message: 'Successfully logged out.', severity: 'success' });
      navigate('/');
    } catch (error) {
      setSnackbar({ open: true, message: 'Error logging out. Please try again.', severity: 'error' });
      console.error('Error in handleLogout:', error);
    }
  };

  const handlePremiumActionClick = () => {
    if (user.hasUsedFreeTrial) {
      navigate('/subscribe');
    } else {
      navigate('/freeTrial');
    }
    setPremiumDialogOpen(false);
  };

  // Manejar el cierre del snackbar
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar({ ...snackbar, open: false });
  };

  // Renderizado cuando la sesión está autenticada
  if (sessionData) {
    return (
      <Box
        sx={{
          padding: '2rem',
          minHeight: '100vh',
          backgroundColor: theme.palette.background.default,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          position: 'relative', // Asegura que el contenido esté en la capa correcta
          zIndex: 1, // Para mantener el contenido por encima
          '&::before': {
            content: 'none', // Desactiva el pseudoelemento global
          },
          '&::after': {
            content: 'none', // Desactiva el pseudoelemento global
          },
        }}
      >
        <Box sx={{ textAlign: 'center', marginTop: '1rem', width: '100%', maxWidth: '1200px' }}>
          <Typography
            variant="body1"
            sx={{ color: theme.palette.text.primary, fontSize: '1.2rem', marginBottom: '1rem' }}
          >
            Sesión autenticada con éxito. ¡Puedes empezar a usar el bot!
          </Typography>

          <Box sx={{ width: '100%', marginBottom: '2rem', textAlign: 'left' }}>
            <PendingResponses userId={userSessionId} />
          </Box>

          <Button
            variant="contained"
            onClick={handleModeChange}
            sx={{
              marginTop: '1rem',
              padding: '1rem',
              backgroundColor: theme.palette.primary.main,
              borderRadius: '8px',
              fontSize: '1.2rem',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
              width: '100%',
              maxWidth: '400px',
              '&:hover': {
                backgroundColor: theme.palette.primary.dark,
              },
            }}
          >
            {isAutonomous ? 'Desactivar modo autónomo' : 'Activar modo autónomo'}
          </Button>

          <Button
            variant="outlined"
            onClick={handleLogout}
            sx={{
              margin: '1rem',
              padding: '1rem',
              borderColor: theme.palette.divider,
              borderRadius: '8px',
              fontSize: '1.2rem',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
              width: '100%',
              maxWidth: '400px',
              color: theme.palette.text.primary,
            }}
          >
            Cerrar Sesión
          </Button>

          <Button
            variant="outlined"
            onClick={handleSendMasive}
            sx={{
              margin: '1rem',
              padding: '1rem',
              borderColor: theme.palette.divider,
              borderRadius: '8px',
              fontSize: '1.2rem',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
              width: '100%',
              maxWidth: '400px',
              color: theme.palette.text.primary,
            }}
          >
            Enviar mensajes masivos
          </Button>
        </Box>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={snackbar.severity}
            sx={{ width: '100%' }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>

          
         {/* Diálogo de suscripción premium */}
         <Dialog
          open={premiumDialogOpen}
          onClose={handlePremiumDialogClose}
          aria-labelledby="premium-dialog-title"
          aria-describedby="premium-dialog-description"
        >
          <DialogTitle id="premium-dialog-title">
            {user.hasUsedFreeTrial ? 'Upgrade to Premium' : 'Start Your Free Trial'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="premium-dialog-description">
              Our premium subscription offers you the ability to send bulk messages, create unlimited sessions, and enjoy priority support.
              {user.hasUsedFreeTrial
                ? 'Subscribe now to unlock all features.'
                : 'Start your free trial today and experience all the features for free for 7 days.'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handlePremiumDialogClose} color="secondary">
              Cancel
            </Button>
            <Button onClick={handlePremiumActionClick} color="primary" autoFocus>
              {user.hasUsedFreeTrial ? 'Subscribe' : 'Free Trial'}
            </Button>
          </DialogActions>
        </Dialog>

      </Box>
    );
  }

  // Renderizado del formulario de configuración
  return (
    <>
      {!showQRCode && (
        <Box
          sx={{
            padding: '2rem',
            minHeight: '100vh',
            backgroundColor: theme.palette.background.default,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <GoHomeButton />
          <Container
            maxWidth="sm"
            sx={{
              padding: '2rem',
              backgroundColor: theme.palette.background.paper,
              borderRadius: '8px',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
              marginTop: '2rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box sx={{ textAlign: 'center', marginBottom: '2rem' }}>
              <Typography
                variant="h4"
                sx={{ color: theme.palette.text.primary, fontSize: '2.5rem', marginBottom: '1rem' }}
              >
                Configura tu Bot de Calidad
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: theme.palette.text.secondary, fontSize: '1.2rem' }}
              >
                Completa la configuración del bot con un archivo PDF para respuestas detalladas.
              </Typography>
            </Box>

            <Box sx={{ width: '100%', marginBottom: '1.5rem' }}>
              <TextField
                label="Nombre de la Empresa"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                variant="outlined"
                fullWidth
                required
                sx={{
                  padding: '1rem',
                  borderRadius: '8px',
                  marginBottom: '1rem',
                  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
                }}
              />
            </Box>

            <Box sx={{ width: '100%', marginBottom: '1.5rem' }}>
              <Button
                variant="outlined"
                component="label"
                startIcon={<CloudUpload />}
                fullWidth
                sx={{
                  padding: '1rem',
                  backgroundColor: theme.palette.secondary.main,
                  borderColor: theme.palette.primary.main,
                  color: theme.palette.text.primary,
                  borderRadius: '8px',
                  fontSize: '1rem',
                  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                }}
              >
                {file ? 'Reemplazar PDF' : 'Subir PDF'}
                <input
                  type="file"
                  hidden
                  accept=".pdf"
                  onChange={handleFileChange}
                />
              </Button>

              {file && (
                <Typography
                  variant="body2"
                  sx={{
                    marginTop: '0.5rem',
                    display: 'block',
                    color: theme.palette.text.secondary,
                  }}
                >
                  Archivo subido: {file.name}
                </Typography>
              )}
            </Box>

            <Box sx={{ width: '100%', marginBottom: '1.5rem' }}>
              <Button
                variant="contained"
                onClick={handleSubmit}
                fullWidth
                disabled={
                  loading ||
                  isAuthenticated ||
                  !companyName.trim() ||
                  !file
                }
                sx={{
                  padding: '1rem',
                  backgroundColor: theme.palette.primary.main,
                  color: '#fff',
                  borderRadius: '8px',
                  fontSize: '1.2rem',
                  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                  '&:hover': {
                    backgroundColor: theme.palette.primary.dark,
                  },
                }}
              >
                {loading ? 'Procesando...' : 'Configurar Bot con PDF'}
              </Button>
            </Box>
          </Container>
        </Box>
      )}

      {/* Sección que se muestra cuando showQRCode es verdadero */}
      {showQRCode && qrCodeData && !isAuthenticated && (
        <Box
          sx={{
            padding: '2rem',
            minHeight: '100vh',
            backgroundColor: theme.palette.background.default,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Typography
            variant="h6"
            sx={{ color: theme.palette.text.primary, marginBottom: '1rem' }}
          >
            Escanea este código QR con tu aplicación de WhatsApp
          </Typography>
          <QRCodeGenerator value={qrCodeData} size={256} />
          <Typography
            variant="body2"
            sx={{ color: theme.palette.text.secondary, marginTop: '1rem' }}
          >
            Esperando autenticación...
          </Typography>
        </Box>
      )}

      {/* Snackbar para mensajes de retroalimentación */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      {/* Diálogo de suscripción premium */}
      <Dialog
        open={premiumDialogOpen}
        onClose={handlePremiumDialogClose}
        aria-labelledby="premium-dialog-title"
        aria-describedby="premium-dialog-description"
      >
        <DialogTitle id="premium-dialog-title">
          {user.hasUsedFreeTrial ? 'Actualizar a Premium' : 'Iniciar tu Prueba Gratis'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="premium-dialog-description">
            Nuestra suscripción premium te ofrece la capacidad de enviar mensajes masivos, crear sesiones ilimitadas y disfrutar de soporte prioritario.
            {user.hasUsedFreeTrial
              ? ' Suscríbete ahora para desbloquear todas las funciones.'
              : ' Inicia tu prueba gratuita hoy y experimenta todas las funciones de forma gratuita durante 7 días.'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePremiumDialogClose} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handlePremiumActionClick} color="primary" autoFocus>
            {user.hasUsedFreeTrial ? 'Suscribirse' : 'Prueba Gratis'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfigBotPDF;
