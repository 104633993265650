import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './components/home';
import Login from './components/login'; // Página de login
import ConfigQR from './components/configQR';
import { ThemeProvider } from 'styled-components';
import  theme  from './theme/theme';
import PendingResponses from './components/pendingResponse';
import PasswordRegister from './components/passwordRegister';
import VerificationSuccess from './components/verificationUser';
import EmailVerificationNotice from './components/emailVerificationNotice';
import UserService from './services/user'; // Importa el servicio
import { useGlobal } from './context/globalContex'; // Importa el contexto global
import SessionsComponent from './components/sessionsComponent';
import ConfigBotPDF from './components/configQRPdf';
import BotTypeSelector from './components/botTypeSelect';
import SaveApiKey from './components/saveApiKey';
import EmailConfirmation from './components/emailConfirmation';
import SendBulkMessages from './components/sendMasiveWS';
import ResponseCard from './components/responseCard';
import QRCodeComponent from './components/qrCode';

const App = () => {
  const [loading, setLoading] = useState(true);
  const { state, dispatch } = useGlobal(); // Usa el estado y el dispatch del contexto global
  const { user } = state; // Accede al usuario desde el estado global

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const userDetails = await UserService.getUserDetails();
        console.log('Detalles del usuario:', userDetails); // Verificar si llega algo aquí
        dispatch({ type: 'SET_USER', payload: userDetails });
      } catch (error) {
        console.error('Error obteniendo detalles del usuario:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchUserDetails();
  }, [dispatch]);

  if (loading) {
    return <div>Loading...</div>; // Mostrar pantalla de carga mientras se verifica el token
  }

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          {/* Rutas públicas */}
          <Route path="/" element={user ? <Navigate to="/dashboard" /> : <Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/verification/:id" element={<EmailConfirmation />}/>
          <Route path="/register-password" element={<PasswordRegister />} />
          <Route path="/verification-success" element={<VerificationSuccess />} />
          <Route path="/verification-notice" element={<EmailVerificationNotice />} />

          {/* Rutas protegidas */}
          <Route 
            path="/dashboard" 
            element={
              user ? <SessionsComponent/> : <Navigate to="/" />
            } 
          />

          <Route 
            path="/selectBot" 
            element={
              user ? <BotTypeSelector/> : <Navigate to="/" />
            } 
          />

<Route 
            path="/saveapikey" 
            element={
              user ? <SaveApiKey/> : <Navigate to="/" />
            } 
          />

<Route 
            path="/sendWsMasive" 
            element={
              user ? <SendBulkMessages/> : <Navigate to="/" />
            } 
          />

          <Route 
            path="/ConfigQR" 
            element={
              user ? <ConfigQR/> : <Navigate to="/" />
            } 
          />

          <Route 
            path="/ConfigQRPdf" 
            element={
              user ? <ConfigBotPDF/> : <Navigate to="/" />
            } 
          />


          <Route 
            path="/pending-response" 
            element={
              user ? <PendingResponses /> : <Navigate to="/" />
            } 
          />
          <Route 
            path="/qr-code" 
            element={
              user ? <QRCodeComponent /> : <Navigate to="/" />
            } 
          />
          <Route 
            path="/response-card" 
            element={
              user ? <ResponseCard userId={user._id} /> : <Navigate to="/" />
            } 
          />

          {/* Ruta para páginas no encontradas */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
