import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Button,
  Typography,
  Card,
  CardContent,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Box,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useGlobal } from '../context/globalContex';
import { useTheme } from '@mui/material/styles';
import UserSessionService from '../services/userSessions';

const SessionsComponent = () => {
  const { state, addUserSession, removeUserSession, updateUserSession } = useGlobal();
  const { user } = state;
  const navigate = useNavigate();
  const theme = useTheme();

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const [premiumDialogOpen, setPremiumDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedSession, setSelectedSession] = useState(null);

  const handleCreateUserSession = async () => {
    console.log('Intentando crear sesión para usuario:', user);

    if (!user.activeSus && user.sessions.length >= 1) {
      setPremiumDialogOpen(true);
      return;
    }

    try {
      const newUserSession = await UserSessionService.createSession({
        userId: user._id,
      });
      
      console.log('Nueva sesión creada:', newUserSession);
      addUserSession(newUserSession);
      
      setSnackbar({
        open: true,
        message: 'New session created successfully',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error detallado al crear sesión:', error);
      setSnackbar({
        open: true,
        message: 'Error creating the session',
        severity: 'error',
      });
    }
  };

  const handleDeleteSession = async (sessionId, event) => {
    event.stopPropagation();
    console.log('Intentando eliminar sesión:', sessionId);
    setSelectedSession(sessionId);
    setDeleteDialogOpen(true);
  };

  const confirmDeleteSession = async () => {
    console.log('Confirmando eliminación de sesión:', selectedSession);
    try {
      await UserSessionService.deleteSession(selectedSession);
      removeUserSession(selectedSession);
      setSnackbar({
        open: true,
        message: 'Session deleted successfully',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error detallado al eliminar sesión:', error);
      setSnackbar({
        open: true,
        message: 'Error deleting the session',
        severity: 'error',
      });
    } finally {
      setDeleteDialogOpen(false);
      setSelectedSession(null);
    }
  };

  const handleReconnectSession = async (sessionId, event) => {
    event.stopPropagation();
    console.log('Intentando reconectar sesión:', sessionId);
    try {
      const reconnectedSession = await UserSessionService.reconnectSession(sessionId);
      console.log('Sesión reconectada:', reconnectedSession);
      updateUserSession(reconnectedSession);
      setSnackbar({
        open: true,
        message: 'Session reconnected successfully',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error detallado al reconectar sesión:', error);
      setSnackbar({
        open: true,
        message: 'Error reconnecting the session',
        severity: 'error',
      });
    }
  };

  const handleCardClick = (userSession) => {
    console.log('Sesión seleccionada:', userSession);
    
    if (!userSession || !userSession._id) {
      console.error('Error: Datos de sesión inválidos:', userSession);
      setSnackbar({
        open: true,
        message: 'Error: Invalid session data',
        severity: 'error',
      });
      return;
    }

    const navigationState = {userSessionId: userSession._id };
    
    if (userSession.isActive === true) {
      console.log('Navegando a pending-response:', navigationState);
      navigate('/pending-response', { state: navigationState });
    } else {
      console.log('Navegando a selectBot:', navigationState);
      navigate('/ConfigQR', { state: navigationState });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handlePremiumDialogClose = () => {
    setPremiumDialogOpen(false);
  };

  const handlePremiumActionClick = () => {
    if (user.hasUsedFreeTrial) {
      navigate('/subscribe');
    } else {
      navigate('/freeTrial');
    }
    setPremiumDialogOpen(false);
  };

  // Verificar estado inicial
  console.log('Estado actual:', {
    user,
    sessions: user?.sessions,
    activeSus: user?.activeSus
  });

  return (
    <Box sx={{ padding: { xs: '10px', sm: '20px' }, overflow: 'hidden' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateUserSession}
            startIcon={<AddIcon />}
            sx={{ 
              marginBottom: '16px',
              width: { xs: '100%', sm: 'auto' },
              padding: { xs: '10px', sm: '6px 16px' },
              minHeight: '48px',
            }}
          >
            Create New Session
          </Button>
        </Grid>

        {user?.sessions && user.sessions.length > 0 ? (
          user.sessions.map((userSession) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={userSession._id}>
              <Card
                onClick={() => handleCardClick(userSession)}
                sx={{
                  position: 'relative',
                  width: '100%',
                  cursor: 'pointer',
                  transition: 'transform 0.2s ease-in-out',
                  '&:hover': {
                    transform: 'scale(1.02)',
                  },
                }}
              >
                <CardContent sx={{ 
                  position: 'relative',
                  minHeight: '100px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '16px',
                }}>
                  <FiberManualRecordIcon 
                    sx={{
                      position: 'absolute',
                      top: 8,
                      left: 8,
                      fontSize: 12,
                      color: userSession.isActive ? 'success.main' : 'warning.main'
                    }}
                  />

                  <Typography
                    variant="h6"
                    component="div"
                    color="text.primary"
                    align="center"
                    sx={{
                      wordBreak: 'break-word',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: 'vertical',
                    }}
                  >
                    {userSession._id || 'Session Name'}
                  </Typography>

                  <Box sx={{ 
                    position: 'absolute', 
                    top: 8, 
                    right: 8, 
                    display: 'flex', 
                    gap: 1 
                  }}>
                    {!userSession.isActive && userSession.updatedAt && (
                      <IconButton
                        size="small"
                        onClick={(e) => handleReconnectSession(userSession._id, e)}
                        color="primary"
                        sx={{ 
                          backgroundColor: 'background.paper',
                          '&:hover': { backgroundColor: 'action.hover' }
                        }}
                      >
                        <RestartAltIcon fontSize="small" />
                      </IconButton>
                    )}
                    <IconButton
                      size="small"
                      onClick={(e) => handleDeleteSession(userSession._id, e)}
                      color="error"
                      sx={{ 
                        backgroundColor: 'background.paper',
                        '&:hover': { backgroundColor: 'action.hover' }
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography variant="body1" color="text.secondary">
              No active sessions.
            </Typography>
          </Grid>
        )}
      </Grid>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbar.message}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseSnackbar}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />

      <Dialog
        open={premiumDialogOpen}
        onClose={handlePremiumDialogClose}
        aria-labelledby="premium-dialog-title"
        aria-describedby="premium-dialog-description"
      >
        <DialogTitle id="premium-dialog-title">
          {user.hasUsedFreeTrial ? 'Upgrade to Premium' : 'Start Your Free Trial'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="premium-dialog-description">
            Our premium subscription offers you the ability to create unlimited sessions, priority support, and access to exclusive features. 
            {user.hasUsedFreeTrial
              ? 'Subscribe now to enjoy all the benefits.'
              : 'Start your free trial today and experience all the features for free for 7 days.'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePremiumDialogClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handlePremiumActionClick} color="primary" autoFocus>
            {user.hasUsedFreeTrial ? 'Subscribe' : 'Free Trial'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">
          Confirm Delete
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete this session? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={confirmDeleteSession} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SessionsComponent;