import React, { useEffect, useState, useCallback } from 'react';
import { 
  Typography, 
  CircularProgress, 
  Box, 
  Snackbar, 
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import ResponseCard from './responseCard';
import { getPendingResponses, setAutonomousMode } from '../services/client';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate, useLocation } from 'react-router-dom';
import { useGlobal } from '../context/globalContex';

const REFRESH_INTERVAL = 30000; // 30s
const SNACKBAR_DURATION = 6000; 

const Container = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
  maxHeight: '80vh',
  overflowY: 'auto',
}));

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(3),
  fontWeight: 600,
}));

const EmptyStateMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  textAlign: 'center',
  padding: theme.spacing(4),
}));

const LoadingWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '200px',
});

const ButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  flexWrap: 'wrap',
}));

const PendingResponses = () => {
  const [pendingResponses, setPendingResponses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isAutonomous, setIsAutonomous] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const [premiumDialogOpen, setPremiumDialogOpen] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const { logoutUser, state } = useGlobal();
  const { user } = state;
  const userId = location.state?.userSessionId;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const showNotification = (message, severity = 'success') => {
    setSnackbar({ open: true, message, severity });
  };

  const handleModeChange = async () => {
    try {
      const newMode = !isAutonomous;
      await setAutonomousMode(userId, newMode);
      setIsAutonomous(newMode);
      showNotification(`Autonomous mode ${newMode ? 'enabled' : 'disabled'}`);
    } catch (error) {
      showNotification('Error changing mode', 'error');
      console.error('Error changing mode:', error);
    }
  };

  const handleSendMasive = () => {
    if (user.activeSus) {
      navigate('/sendWsMasive', { state: { userId } });
    } else {
      setPremiumDialogOpen(true);
    }
  };

  const handleLogout = async () => {
    try {
      await logoutUser();
      showNotification('Successfully logged out');
      navigate('/');
    } catch (error) {
      showNotification('Error logging out. Please try again', 'error');
      console.error('Error in handleLogout:', error);
    }
  };

  const handlePremiumActionClick = () => {
    const route = user?.hasUsedFreeTrial ? '/subscribe' : '/freeTrial';
    navigate(route);
    setPremiumDialogOpen(false);
  };

  // Obtiene la lista de mensajes pendientes
  const fetchPendingResponses = useCallback(async () => {
    try {
      setLoading(true);
      const responses = await getPendingResponses(userId);
      setPendingResponses(Array.isArray(responses) ? responses : []);
    } catch (error) {
      console.error('Error fetching pending responses:', error);
      showNotification('No se pudieron cargar las respuestas pendientes.', 'error');
    } finally {
      setLoading(false);
    }
  }, [userId]);

  // Elimina un mensaje localmente tras borrarlo en backend
  const handleMessageDeleted = (deletedId) => {
    setPendingResponses(prev => prev.filter(r => r.id !== deletedId));
    showNotification('Mensaje eliminado correctamente');
  };

  useEffect(() => {
    if (!userId) {
      navigate('/');
      return;
    }
    fetchPendingResponses();
    const interval = setInterval(fetchPendingResponses, REFRESH_INTERVAL);
    return () => clearInterval(interval);
  }, [fetchPendingResponses, navigate, userId]);

  return (
    <>
      <Container>
        <Box 
          sx={{ 
            display: 'flex', 
            flexDirection: isSmallScreen ? 'column' : 'row',
            justifyContent: 'space-between', 
            alignItems: isSmallScreen ? 'stretch' : 'center', 
            mb: 3,
            gap: 2
          }}
        >
          <Title variant={isSmallScreen ? 'h5' : 'h4'}>
            Pending GPT Responses
          </Title>

          <ButtonsContainer>
            <Button
              variant="contained"
              onClick={handleModeChange}
              color="primary"
              fullWidth={isSmallScreen}
            >
              {isAutonomous ? 'Disable Autonomous' : 'Enable Autonomous'}
            </Button>

            <Button
              variant="outlined"
              onClick={handleSendMasive}
              color="primary"
              fullWidth={isSmallScreen}
            >
              Send Bulk Messages
            </Button>

            <Button
              variant="outlined"
              onClick={handleLogout}
              color="secondary"
              fullWidth={isSmallScreen}
            >
              Logout
            </Button>
          </ButtonsContainer>
        </Box>

        {loading ? (
          <LoadingWrapper>
            <CircularProgress size={40} thickness={4} />
          </LoadingWrapper>
        ) : pendingResponses.length === 0 ? (
          <EmptyStateMessage variant="body1">
            No pending responses at the moment.
          </EmptyStateMessage>
        ) : (
          pendingResponses.map((resp) => (
            <ResponseCard
              key={resp.id}
              userId={userId}
              response={resp}
              onMessageDeleted={handleMessageDeleted}
            />
          ))
        )}
      </Container>

      {/* Dialog Premium */}
      <Dialog
        open={premiumDialogOpen}
        onClose={() => setPremiumDialogOpen(false)}
      >
        <DialogTitle>
          {user?.hasUsedFreeTrial ? 'Upgrade to Premium' : 'Start Your Free Trial'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Our premium subscription offers you the ability to send bulk messages, create unlimited sessions, and enjoy priority support.
            {user?.hasUsedFreeTrial
              ? ' Subscribe now to unlock all features.'
              : ' Start your free trial today and experience all the features for free for 7 days.'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPremiumDialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button 
            onClick={handlePremiumActionClick}
            color="primary" 
            autoFocus
          >
            {user?.hasUsedFreeTrial ? 'Subscribe' : 'Start Free Trial'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={SNACKBAR_DURATION}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          elevation={6}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default PendingResponses;
