import React, { useState } from 'react';
import { Typography, Button, TextField, Box, Snackbar, Alert, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useGlobal } from '../context/globalContex';
import { useNavigate, useLocation } from 'react-router-dom';
import { getQRCode } from '../services/client'; // tu endpoint para obtener el QR en backend
import SessionService from '../services/session';
import GoHomeButton from './goHomeButton';

const ConfigQR = () => {
  const [agentId, setAgentId] = useState('');
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });

  const theme = useTheme();
  const { logoutUser, state } = useGlobal();
  const { user } = state;
  const navigate = useNavigate();
  const location = useLocation();
  
  // userSessionId proviene de la ruta actual (por ejemplo si lo pasaste al navegar)
  const userSessionId = location.state?.userSessionId;
  console.log("userSessionId:", userSessionId);

  // Maneja el "Generar QR"
  const handleGenerateQR = async () => {
    if (!agentId) {
      setSnackbar({ open: true, message: 'Please provide an agent ID.', severity: 'error' });
      return;
    }
    if (!userSessionId) {
      setSnackbar({ open: true, message: 'No userSessionId found to link this session.', severity: 'error' });
      return;
    }

    setLoading(true);
    try {
      // Crea o actualiza la sesión en backend
      const sessionData = { userId: userSessionId, agentId };
      await SessionService.createSession(sessionData);

      // Luego obtenemos el QR
      const qrData = await getQRCode(userSessionId);
      if (qrData && qrData.qr) {
        // Navegamos a la vista del QR
        navigate('/qr-code', { state: { qrCodeData: qrData.qr, userSessionId } });
      } else {
        setSnackbar({ open: true, message: 'Error generating QR code.', severity: 'error' });
      }
    } catch (error) {
      console.error('Error creating session or generating QR:', error);
      setSnackbar({ open: true, message: 'Error creating session or generating QR code.', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  // Logout
  const handleLogout = async () => {
    try {
      await logoutUser();
      setSnackbar({ open: true, message: 'Successfully logged out.', severity: 'success' });
      navigate('/');
    } catch (error) {
      setSnackbar({ open: true, message: 'Error logging out. Please try again.', severity: 'error' });
      console.error('Error in handleLogout:', error);
    }
  };

  // Cierra el snackbar
  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box 
      sx={{ 
        padding: '2rem', 
        minHeight: '100vh', 
        backgroundColor: theme.palette.background.default, 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center' 
      }}
    >
      <GoHomeButton />
      
      <Box 
        sx={{ 
          width: '100%', 
          maxWidth: '600px', 
          textAlign: 'center', 
          marginBottom: '2rem' 
        }}
      >
        <Typography 
          variant="h4" 
          sx={{ color: theme.palette.text.primary, marginBottom: '1rem' }}
        >
          Configure your WsBot
        </Typography>

        <TextField
          label="Agent ID"
          value={agentId}
          onChange={(e) => setAgentId(e.target.value)}
          fullWidth
          sx={{
            marginBottom: '1.5rem',
            backgroundColor: theme.palette.background.paper,
            borderRadius: '8px',
          }}
        />

        <Button
          variant="contained"
          onClick={handleGenerateQR}
          disabled={loading}
          fullWidth
          sx={{
            marginTop: '1.5rem',
            padding: '1rem',
            backgroundColor: theme.palette.primary.main,
            borderRadius: '8px',
            fontSize: '1.2rem',
          }}
        >
          {loading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Generate QR for WhatsApp'}
        </Button>

        <Button
          variant="contained"
          onClick={handleLogout}
          fullWidth
          sx={{
            marginTop: '1.5rem',
            padding: '1rem',
            backgroundColor: theme.palette.secondary.main,
            borderRadius: '8px',
            fontSize: '1.2rem',
          }}
        >
          Logout
        </Button>
      </Box>

      {/* Snackbar de notificaciones */}
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar} 
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity} 
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ConfigQR;
