import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  Box,
  IconButton,
  Divider,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BlockIcon from '@mui/icons-material/Block';

import { sendValidatedResponse, deleteMessage } from '../services/client';

//
// ==== ESTILOS ====
//

// Card con animación de salida si se aplica la clase "exiting"
const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.spacing(1),
  transition: 'box-shadow 0.3s ease',
  position: 'relative',

  '&:hover': {
    boxShadow: theme.shadows[3],
  },

  // Animación de salida
  '&.exiting': {
    opacity: 0,
    transform: 'scale(0.95)',
    transition: 'opacity 0.3s ease, transform 0.3s ease',
  },
}));

const Section = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: 600,
  fontSize: '0.875rem',
  marginBottom: theme.spacing(0.5),
}));

const SectionContent = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '1rem',
  lineHeight: 1.6,
}));

const ActionButtons = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: theme.spacing(1),
  marginTop: theme.spacing(2),
}));

// Imagen miniatura: máx 400x400
const ImagePreview = styled('img')(({ theme }) => ({
  maxWidth: '400px',
  maxHeight: '400px',
  width: 'auto',
  height: 'auto',
  marginTop: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  cursor: 'pointer',
}));

// Imagen grande en modal (90% viewport)
const ImageFull = styled('img')(() => ({
  maxWidth: '90vw',
  maxHeight: '90vh',
  width: 'auto',
  height: 'auto',
}));

/**
 * `ResponseCard`:
 * Muestra el historial (summarizedHistory), 
 * el mensaje del usuario (userResponse) y
 * la respuesta GPT (gptResponse) editable.
 * Permite aprobar, editar y rechazar (con animaciones).
 */
const ResponseCard = ({ userId, response, onMessageDeleted }) => {
  // gptResponse editable
  const [editedResponse, setEditedResponse] = useState(response.gptResponse || '');
  const [isEditing, setIsEditing] = useState(false);

  // Para indicar que estamos procesando (approve/save/delete)
  const [isProcessing, setIsProcessing] = useState(false);

  // Para animación de salida al eliminar
  const [exiting, setExiting] = useState(false);

  // Para mostrar imagen en grande en un modal
  const [openImage, setOpenImage] = useState(null);

  // Maneja las acciones (approve, save, delete)
  const handleAction = async (action) => {
    setIsProcessing(true);
    try {
      switch (action) {
        case 'approve':
        case 'save':
          // Enviar la respuesta final validada
          // (mientras, botón muestra spinner)
          await sendValidatedResponse(userId, editedResponse, response.id);
          if (action === 'save') {
            // salimos de modo edición
            setIsEditing(false);
          }
          break;

        case 'delete':
          // Primero animamos la salida del Card
          setExiting(true);
          // Esperamos ~300ms (la duración de la transición) 
          // antes de eliminarlo realmente en el backend y 
          // notificar al padre que lo retire de la lista
          setTimeout(async () => {
            await deleteMessage(response.id);
            onMessageDeleted?.(response.id);
          }, 300);
          break;

        default:
          break;
      }
    } catch (error) {
      console.error(`Error processing ${action}:`, error);
    } finally {
      setIsProcessing(false);
    }
  };

  // Renderiza la lista de submensajes del historial
  const renderSummarizedHistory = () => {
    if (!Array.isArray(response.summarizedHistory) || response.summarizedHistory.length === 0) {
      return <SectionContent>No conversation history available</SectionContent>;
    }

    return (
      <Box sx={{ pl: 1 }}>
        {response.summarizedHistory.map((msg, idx) => (
          <Box key={idx} sx={{ mb: 1 }}>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              {msg.role === 'assistant' ? 'Assistant:' : 'User:'}
            </Typography>
            {msg.text && (
              <SectionContent sx={{ ml: 2 }}>{msg.text}</SectionContent>
            )}
            {msg.imageUrl && (
              <Box sx={{ ml: 2 }}>
                <ImagePreview
                  src={msg.imageUrl}
                  alt={`history-img-${idx}`}
                  onClick={() => setOpenImage(msg.imageUrl)}
                />
              </Box>
            )}
          </Box>
        ))}
      </Box>
    );
  };

  // Renderiza userResponse
  const renderUserResponse = () => {
    const ur = response.userResponse || {};
    const hasText = ur.text;
    const hasImage = ur.imageUrl;

    if (!hasText && !hasImage) {
      return <SectionContent>No user message available</SectionContent>;
    }
    return (
      <Box>
        {hasText && <SectionContent>{ur.text}</SectionContent>}
        {hasImage && (
          <ImagePreview
            src={ur.imageUrl}
            alt="user-img"
            onClick={() => setOpenImage(ur.imageUrl)}
          />
        )}
      </Box>
    );
  };

  return (
    <>
      <StyledCard 
        variant="outlined" 
        className={exiting ? 'exiting' : ''}
      >
        <CardContent>
          {/* Historial */}
          <Section>
            <SectionTitle>Conversation History</SectionTitle>
            {renderSummarizedHistory()}
          </Section>

          <Divider sx={{ my: 2 }} />

          {/* Mensaje del usuario */}
          <Section>
            <SectionTitle>User Message</SectionTitle>
            {renderUserResponse()}
          </Section>

          <Divider sx={{ my: 2 }} />

          {/* GPT Response */}
          <Section>
            <SectionTitle>GPT Response</SectionTitle>
            {isEditing ? (
              <TextField
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                value={editedResponse}
                onChange={(e) => setEditedResponse(e.target.value)}
                disabled={isProcessing}
                sx={{ mt: 1 }}
              />
            ) : (
              <SectionContent>{editedResponse}</SectionContent>
            )}
          </Section>

          {/* Botones de acción */}
          <ActionButtons>
            {isEditing ? (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={
                    isProcessing ? <CircularProgress size={16} color="inherit" /> : <CheckCircleIcon />
                  }
                  onClick={() => handleAction('save')}
                  disabled={isProcessing}
                >
                  {isProcessing ? 'Saving...' : 'Save & Send'}
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => setIsEditing(false)}
                  disabled={isProcessing}
                >
                  Cancel
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={
                    isProcessing ? <CircularProgress size={16} color="inherit" /> : <CheckCircleIcon />
                  }
                  onClick={() => handleAction('approve')}
                  disabled={isProcessing}
                >
                  {isProcessing ? 'Sending...' : 'Approve'}
                </Button>

                <IconButton
                  color="primary"
                  onClick={() => setIsEditing(true)}
                  disabled={isProcessing}
                >
                  <EditIcon />
                </IconButton>

                <Button
                  variant="contained"
                  color="error"
                  startIcon={
                    isProcessing ? <CircularProgress size={16} color="inherit" /> : <BlockIcon />
                  }
                  onClick={() => handleAction('delete')}
                  disabled={isProcessing}
                >
                  {isProcessing ? 'Deleting...' : 'Reject'}
                </Button>
              </>
            )}
          </ActionButtons>
        </CardContent>
      </StyledCard>

      {/* Modal para imagen en grande */}
      <Dialog
        open={Boolean(openImage)}
        onClose={() => setOpenImage(null)}
        maxWidth="xl"
      >
        <DialogTitle>Image Preview</DialogTitle>
        <DialogContent>
          {openImage && (
            <Box sx={{ textAlign: 'center' }}>
              <ImageFull src={openImage} alt="preview-large" />
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ResponseCard;
