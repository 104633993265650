import axios from 'axios';

const API_URL = 'https://serverpro-igs6.onrender.com/api/userSessions'; // Cambia la URL según tu entorno
// const API_URL = 'http://localhost:4001/api/userSessions'; // Cambia la URL según tu entorno
// const API_URL = 'https://serverpro-igs6.onrender.com/api/userSessions'; // Cambia la URL según tu entorno

const UserSessionService = {

  // Crear una nueva sesión
  createSession: async (sessionData) => {
    try {
      const response = await axios.post(`${API_URL}/create`, sessionData);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error;
    }
  },

  // Obtener todas las sesiones
  getAllSessions: async () => {
    try {
      const response = await axios.get(API_URL);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error;
    }
  },

  // Obtener una sesión por ID
  getSessionById: async (sessionId) => {
    try {
      const response = await axios.get(`${API_URL}/${sessionId}`);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error;
    }
  },

  // Actualizar una sesión por ID
  updateSession: async (sessionId, sessionData) => {
    try {
      const response = await axios.put(`${API_URL}/${sessionId}`, sessionData);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error;
    }
  },

  checkSessionActive: async (sessionId) => {
    try {
      const response = await axios.get(`${API_URL}/check/${sessionId}`);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error;
    }
  },

  // Eliminar una sesión por ID
  deleteSession: async (sessionId) => {
    try {
      const response = await axios.delete(`${API_URL}/${sessionId}`);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error;
    }
  },
};

export default UserSessionService;
