// BotTypeSelector.js
import React, { useEffect } from 'react';
import {
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid2,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const BotTypeSelector = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();
  const userSessionId = location.state?.userSessionId;

  const handleSelectBot = (botType) => {
    const navigationState = {userSessionId: userSessionId };
    console.log('Navigating with userSessionId:', navigationState);
    if (botType === 'efficient') {
      navigate(`/ConfigQRPdf`, { state: { navigationState } });
    } else {
      navigate(`/ConfigQR`, { state: { navigationState } });
    }
  };

  useEffect(() => {
    console.log('Received userSessionId in BotTypeSelector:', userSessionId);
  }, [userSessionId]);

  return (
    <Grid2 container justifyContent="center" sx={{ margin: '2rem' }}>
      <Grid2 item xs={12} sm={10} md={8} lg={6}>
        <Typography
          variant="h3"
          sx={{
            color: theme.palette.text.primary,
            fontSize: theme.typography.h3.fontSize,
          }}
        >
          Choose the Type of Bot
        </Typography>
        <Typography
          variant="body1"
          sx={{ color: theme.palette.text.secondary, marginBottom: '1rem' }}
        >
          Select the type of bot you want to configure:
        </Typography>

        {/* Accordion for Quality Efficient Bot */}
        <Accordion
          sx={{
            backgroundColor: theme.palette.primary.main,
            borderRadius: '5px',
            marginBottom: '1rem',
            boxShadow: 'none',
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.text.primary }} />}
          >
            <Typography sx={{ color: theme.palette.text.primary }}>
              Quality Efficient Bot (requires uploading a PDF)
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ color: theme.palette.text.primary }}>
              This bot specializes in providing detailed responses based on a PDF uploaded by the user.
            </Typography>
            <Button
              variant="contained"
              fullWidth
              onClick={() => handleSelectBot('efficient')}
              sx={{
                marginTop: '1rem',
                backgroundColor: theme.palette.text.primary,
                color: theme.palette.primary.main,
                '&:hover': {
                  backgroundColor: theme.palette.text.secondary,
                },
              }}
            >
              Configure Bot
            </Button>
          </AccordionDetails>
        </Accordion>

        {/* Accordion for Cost Efficient Bot */}
        <Accordion
          sx={{
            backgroundColor: theme.palette.text.primary,
            borderRadius: '5px',
            boxShadow: 'none',
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.primary.main }} />}
          >
            <Typography sx={{ color: theme.palette.primary.main }}>
              Cost Efficient Bot
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ color: theme.palette.primary.main }}>
              This bot is a more economical option that doesn't require uploading documents, focusing on providing quick responses.
            </Typography>
            <Button
              variant="contained"
              fullWidth
              onClick={() => handleSelectBot('basic')}
              sx={{
                marginTop: '1rem',
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.text.primary,
                '&:hover': {
                  backgroundColor: theme.palette.primary.dark,
                },
              }}
            >
              Configure Bot
            </Button>
          </AccordionDetails>
        </Accordion>
      </Grid2>
    </Grid2>
  );
};

export default BotTypeSelector;
