import React, { useEffect, useState, useCallback } from 'react';
import {
  Typography,
  CircularProgress,
  Box,
  Snackbar,
  Alert,
  Button,
} from '@mui/material';
import { QRCode as QRCodeGenerator } from 'react-qrcode-logo';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import UserSessionService from '../services/userSessions';

const QRCodeComponent = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  // Extrae la información del state (pasado desde otra ruta)
  const qrCodeData = location.state?.qrCodeData;
  const userId = location.state?.userSessionId;

  // Estado para indicar si está conectado o no (solo si se detectó que la sesión ya está activa)
  const [isConnected, setIsConnected] = useState(false);
  // Muestra un spinner al inicio (ej. mientras cargamos QR)
  const [loading, setLoading] = useState(true);

  // Manejo de Snackbar para avisos
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info', // 'success', 'error', 'warning', 'info'
  });

  // ---------------------------------------------
  // Al montar el componente:
  //   - si no hay userId o qrCodeData => navigate('/')
  //   - caso contrario => setLoading(false) porque ya podemos mostrar el QR
  // ---------------------------------------------
  useEffect(() => {
    if (!userId || !qrCodeData) {
      navigate('/');
      return;
    }
    setLoading(false);
  }, [userId, qrCodeData, navigate]);

  // ---------------------------------------------
  // Función para chequear la sesión manualmente
  // ---------------------------------------------
  const checkSession = useCallback(async () => {
    if (!userId) return;

    try {
      const isActive = await UserSessionService.checkSessionActive(userId);
      const navigationState = { userSessionId: userId };

      if (isActive) {
        // Si la sesión está activa => mostramos éxito, navegamos
        setIsConnected(true);
        setSnackbar({
          open: true,
          message: 'Successfully connected! Redirecting...',
          severity: 'success',
        });
        // Navegamos a /pending-response tras 2 seg
        setTimeout(() => {
          navigate('/pending-response', { state: navigationState });
        }, 2000);
      } else {
        // Si no está activa => mostrar aviso
        setSnackbar({
          open: true,
          message: 'Session not ready. Please try again.',
          severity: 'warning',
        });
      }
    } catch (error) {
      console.error('Error checking session:', error);
      setSnackbar({
        open: true,
        message: 'Error checking connection status. Please try again.',
        severity: 'error',
      });
    }
  }, [userId, navigate]);

  // Cierra el snackbar
  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  // Handler del botón => invoca checkSession manualmente
  const handleCheckNow = async () => {
    await checkSession();
  };

  // Si no hay QR, no renderizamos nada
  if (!qrCodeData) return null;

  return (
    <Box
      sx={{
        textAlign: 'center',
        marginTop: '2rem',
        backgroundColor: theme.palette.background.paper,
        padding: '2rem',
        borderRadius: '8px',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        color: theme.palette.text.primary,
      }}
    >
      {/* Si no está conectado, seguimos mostrando el QR */}
      {!isConnected && (
        <>
          <Typography
            variant="h5"
            sx={{ color: theme.palette.text.primary, marginBottom: '1rem' }}
          >
            Scan this QR code with your WhatsApp application
          </Typography>
          {loading ? (
            <CircularProgress
              size={60}
              sx={{ color: theme.palette.primary.main, marginTop: '1rem' }}
            />
          ) : (
            <>
              <QRCodeGenerator value={qrCodeData} size={256} />
              <Box sx={{ marginTop: '1.5rem' }}>
                <Button variant="contained" color="primary" onClick={handleCheckNow}>
                  I Have Scanned the QR
                </Button>
              </Box>
            </>
          )}
        </>
      )}

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default QRCodeComponent;
