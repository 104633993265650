import axios from 'axios';

// const API_BASE_URL = 'https://serverpro-3ohy.onrender.com/api'; // Ajusta esto según la configuración de tu backend
// const API_BASE_URL = 'https://apiwsproject-latest.onrender.com/api'; // Ajusta esto según la configuración de tu backend
const API_BASE_URL = 'https://serverpro-igs6.onrender.com/api'; // Ajusta esto según la configuración de tu backend

export const getQRCode = async (userId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/qr/${userId}`);
    return response.data;
  } catch (error) {
    // Puedes personalizar el manejo de errores aquí si es necesario
    throw error.response ? error.response.data : error;
  }
};


export const getPendingResponses = async (userId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/messages/${userId}/pendingResponse`);
      return response.data.responses;
    } catch (error) {
      throw error.response ? error.response.data : error;
    }
  };

export const sendValidatedResponse = async (userId, validatedResponse, messageId) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/messages/${userId}/validateResponse`, {
      validatedResponse,
      messageId, // Ensure messageId is sent here
    });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

export const checkSessionStatus = async (userId) => {
  try {
      const response = await axios.get(`${API_BASE_URL}/qr/${userId}/sessionStatus`);
      return response.data; // Devuelve toda la información relevante del cliente
  } catch (error) {
      throw error.response ? error.response.data : error;
  }
};


export const setAutonomousMode = async (userId, isAutonomous) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/messages/${userId}/setMode`, {
      isAutonomous,
    });

    return response.data; // Successfully updated mode
  } catch (error) {
    console.error('Error setting autonomous mode:', error.response?.data || error.message);
    throw error;
  }
};

export const deleteMessage = async (messageId) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/messages/${messageId}`);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

export const enviarMasivoCSV = async (userId, selectedFile) => {
  const formData = new FormData();
  formData.append('file', selectedFile, selectedFile.name);  // Include the filename

  try {
    const response = await axios.post(`${API_BASE_URL}/messages/${userId}/enviarMasivo`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error enviando archivo CSV:', error.response?.data || error.message);
    throw error;
  }
};
